export const menu = [
  {
    title: "首頁",
    pathname: "/",
    submenu: [],
  },
  {
    title: "展區介紹",
    pathname: "/exhibition",
    submenu: [
      {
        name: "古董經典",
        pathname: "/exhibition/oldCar",
        id: null,
      },
      {
        name: "競速改裝",
        pathname: "/exhibition/speedCar",
        id: null,
      },
      {
        name: "優雅旅行",
        pathname: "/exhibition/travelCar",
        id: null,
      },
    ],
  },
  {
    title: "主題活動",
    pathname: "/theme",
    submenu: [
      {
        name: "甩尾車神戰",
        pathname: "/theme/theme1",
        id: null,
      },
      {
        name: "紳士之路",
        pathname: "theme/theme2",
        id: null,
      },
      {
        name: "玩在藝起",
        pathname: "theme/theme3",
        id: null,
      },
      {
        name: "演唱會",
        pathname: "theme/theme4",
        id: null,
      },
    ],
  },
  {
    title: "展車票選",
    pathname: "/vote",
    submenu: [
      {
        name: "人氣王展車",
        pathname: "/vote",
        id: "voteHotCar",
      },
      {
        name: "競速改裝",
        pathname: "/vote",
        id: "voteSpeedCar",
      },
      {
        name: "古董經典",
        pathname: "/vote",
        id: "voteOldCar",
      },
      {
        name: "優雅旅行",
        pathname: "/vote",
        id: "voteTravelCar",
      },
    ],
  },
  {
    title: "靚車試駕",
    pathname: "/drive",
    submenu: [],
  },
  {
    title: "特色市集",
    pathname: "/market",
    submenu: [],
  },
];

export const exhibitionMeta = [
  {
    title: "古董經典",
    titleImg: "old_title.png",
    img: "old_car.png",
    btnImg: "blue_more.png",
    desc: "經典名車到限量腕錶皆因珍罕卓越而使人著迷。兜風生活節 2022特別規劃古典車 展區，讓人彷彿走進時光膠囊，歌頌傳奇經典，重返醉心於輝煌舊日。 如各項經典賽事賽事，此次聚會讓古董車愛好者與蒐藏家共聚一堂，期望與志同道合者之間的邂逅讓更多人 感受歷久彌新的經典。",
    link: "/exhibition",
    target: "_blank",
    id: "oldCar",
    detials: {
      titleImg: "old_title.png",
      pcImg: "pc_exhibition_old_hero.png",
      mImg: "m_exhibition_old_hero.png",
      pcBg: "pc_exhibition_old_bg.png",
      mBg: "m_exhibition_old_bg.png",
      desc: [
        "經典名車到限量腕錶皆因珍罕卓越而使人著迷。兜風生活節 2022特別規劃古典車 展區，讓人彷彿走進時光膠囊，歌頌傳奇經典，重返醉心於輝煌舊日。",
        "如各項經典賽事賽事，此次聚會讓古董車愛好者與蒐藏家共聚一堂，期望與志同道合者之間的邂逅讓更多人 感受歷久彌新的經典。",
      ],
    },
  },
  {
    title: "競速改裝",
    titleImg: "speed_title.png",
    img: "speed_car.png",
    btnImg: "orange_more.png",
    desc: "改裝車迷人之處，除了突破想像的設計與科技，還有經由熱血車主及名家改裝後突然不可一世的市售車款。不管是符合個性時尚需求的車身 改裝，還是大幅提升車輛性能的動力改裝，改裝車在車流中顯得卓爾不群，也是視覺注目焦點。 想要體驗令人熱血沸騰的改裝文化，顛覆你對汽車的常規想像，讓獨特且風雅時尚的改裝車，開闊你的國際新視野",
    link: "/exhibition",
    target: "_blank",
    id: "speedCar",
    detials: {
      titleImg: "speed_title.png",
      pcImg: "pc_exhibition_speed_hero.png",
      mImg: "m_exhibition_speed_hero.png",
      pcBg: "pc_exhibition_speed_bg.png",
      mBg: "m_exhibition_speed_bg.png",
      desc: [
        "改裝車迷人之處，除了突破想像的設計與科技，還有經由熱血車主及名家改裝後突然不可一世的市售車款。不管是符合個性時尚需求的車身 改裝，還是大幅提升車輛性能的動力改裝，改裝車在車流中顯得卓爾不群，也是視覺注目焦點。 想要體驗令人熱血沸騰的改裝文化，顛覆你對汽車的常規想像，讓獨特且風雅時尚的改裝車，開闊你的國際新視野。",
      ],
    },
  },
  {
    title: "優雅旅行",
    titleImg: "travel_title.png",
    img: "travel_car.png",
    btnImg: "brown_more.png",
    desc: "台灣獨有的山林溪河環境，適合野營、泛舟、溯溪以及各種山野戶外活動，這也讓懂得品味生活的戶外玩家能開著車帶著親朋好友探索秘景，享受令人心曠神怡的靜謐美景。一起來看看哪些玩家開著優雅的旅行車，享受夢幻仙境般的自然風景吧！",
    link: "/exhibition",
    target: "_blank",
    id: "travelCar",
    detials: {
      titleImg: "travel_title.png",
      pcImg: "pc_exhibition_travel_hero.png",
      mImg: "m_exhibition_travel_hero.png",
      pcBg: "pc_exhibition_travel_bg.png",
      mBg: "m_exhibition_travel_bg.png",
      desc: [
        "台灣獨有的山林溪河環境，適合野營、泛舟、溯溪以及各種山野戶外活動，這也讓懂得品味生活的戶外玩家能開著車帶著親朋好友探索秘景，享受令人心曠神怡的靜謐美景。一起來看看哪些玩家開著優雅的旅行車，享受夢幻仙境般的自然風景吧！",
      ],
    },
  },
];

export const themeTabsMeta = [
  {
    title: "甩尾車神戰",
    id: "theme1",
  },
  {
    title: "紳士之路",
    id: "theme2",
  },
  {
    title: "玩在藝起",
    id: "theme3",
  },
  {
    title: "演唱會",
    id: "theme4",
  },
];

export const voteTabsMeta = [
  {
    title: "人氣王展車",
    id: "voteHotCar",
  },
  {
    title: "競速改裝",
    id: "voteSpeedCar",
  },
  {
    title: "古董經典",
    id: "voteOldCar",
  },
  {
    title: "優雅旅行",
    id: "voteTravelCar",
  },
];

export const themeCarsMeta = [
  {
    img: "theme1.png",
    target: "_blank",
    title: "甩尾車神戰",
    desc: "樂團表演，DJ秀 、 精彩舞蹈 、 品牌時間，嗨翻無上限",
    id: "theme1",
    detials: {
      titleImg: "theme1_title.png",
      pcImg: "pc_theme1_hero.png",
      mImg: "m_theme_hero.png",
      mSubHero: "m_theme1_subhero.png",
      desc: [
        "硝煙交織氮氣的輪胎味，愛車人士最愛的汽車甩尾大賽。本次活動將廣邀專業賽車手甩尾激烈操駕，將電影情節搬入真實生活。近距離感受速度與激情，熱血一次，夫復何求？",
      ],
      scenes: [
        {
          title: "場次一",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene1.png",
        },
        {
          title: "場次二",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene2.png",
        },
      ],
    },
  },
  {
    img: "theme2.png",
    target: "_blank",
    title: "紳士之路",
    desc: "樂團表演，DJ秀 、 精彩舞蹈 、 品牌時間，嗨翻無上限",
    id: "theme2",
    detials: {
      titleImg: "theme2_title.png",
      pcImg: "pc_theme2_hero.png",
      mImg: "m_theme_hero.png",
      mSubHero: "m_theme2_subhero.png",
      desc: [
        "誰說只有名模才能走秀？整個城市都將是稀世汽車伸展台。紳士之路古董車走秀仿效美國最大圓石灘車展，傳奇車款在活動期間以優雅姿態漫餘名車走秀台，搭配追焦手記錄這些名車紳士，將優雅品味、經典風格展現給觀眾。",
      ],
      scenes: [
        {
          title: "場次一",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene1.png",
        },
        {
          title: "場次二",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene2.png",
        },
      ],
    },
  },
  {
    img: "theme3.png",
    target: "_blank",
    title: "玩在藝起",
    desc: "樂團表演，DJ秀 、 精彩舞蹈 、 品牌時間，嗨翻無上限",
    id: "theme3",
    detials: {
      titleImg: "theme3_title.png",
      pcImg: "pc_theme3_hero.png",
      mImg: "m_theme_hero.png",
      mSubHero: "m_theme3_subhero.png",
      desc: [
        "旅遊就要全家大小都能盡興！有音樂、遊戲、老少咸宜都愛看的娛樂表演，一起度過歡樂輕鬆的時光，透過生動演出將歡樂與笑容傳遞給現場參與的民眾。",
      ],
      scenes: [
        {
          title: "場次一",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene1.png",
        },
        {
          title: "場次二",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene2.png",
        },
      ],
    },
  },
  {
    img: "theme4.png",
    target: "_blank",
    title: "演唱會",
    desc: "樂團表演，DJ秀 、 精彩舞蹈 、 品牌時間，嗨翻無上限",
    id: "theme4",
    detials: {
      titleImg: "theme4_title.png",
      pcImg: "pc_theme4_hero.png",
      mImg: "m_theme_hero.png",
      mSubHero: "m_theme4_subhero.png",
      desc: [
        "一首首嗨歌陪你遊山玩水，一首首情歌陪你浪漫約會",
        "敲著方向盤、打著節奏，音樂就是你一路上的好朋友",
        "南國秋夜，讓音樂為你加足馬力，啟動心的引擎，由時下最夯的實力派歌手輪番上陣，與你一起創造這趟旅程的全新回憶與感動。",
      ],
      scenes: [
        {
          title: "場次一",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene1.png",
        },
        {
          title: "場次二",
          desc: "文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字文字",
          img: "theme1_scene2.png",
        },
      ],
    },
  },
];

export const voteMeta = [
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot and Old 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteOldCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Old 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteOldCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Travel 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteTravelCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Speed10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteSpeedCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Speed10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteSpeedCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Speed10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteSpeedCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Travel 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteTravelCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Speed10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteSpeedCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Old 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteOldCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Travel 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteTravelCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Old 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteOldCar"],
  },
  {
    imgs: ["home/vote/vote1.png", "home/vote/vote1.png", "home/vote/vote1.png"],
    title: "Hot Old 10/15～即將亮相！",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
    votes: 999,
    category: ["voteHotCar", "voteOldCar"],
  },
];

export const driveMeta = [
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
  {
    imgs: [
      "home/drive/drive1.png",
      "home/drive/drive1.png",
      "home/drive/drive1.png",
    ],
    title: "車友趣 CAR2DUDE",
  },
];

export const partnerMMeta = [
  {
    img: "m_logo1.png",
    title: "車友趣",
  },
  {
    img: "m_logo2.png",
    title: "好事聯播網",
  },
  {
    img: "m_logo3.png",
    title: "daex",
  },
];

export const partnerPcMeta = [
  {
    img: "pc_logo1.png",
    title: "車友趣",
  },
  {
    img: "pc_logo2.png",
    title: "好事聯播網",
  },
  {
    img: "pc_logo3.png",
    title: "daex",
  },
];

export const marketMeta = [
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
  {
    imgs: ["market/market1.png", "market/market1.png", "market/market1.png"],
    title: "攤位名稱攤位名稱",
    no: "A00001",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla fugiat impedit perferendis labore ullam aliquam voluptate eius voluptatem. Explicabo, non.",
  },
];
